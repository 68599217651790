<template>
  <div>
    <b-modal
      ref="modal-load-price-confirm'"
      id="modal-load-price-confirm'"
      ok-only
      ok-title="Conferma"
      centered
      :visible="show"
      size="lg"
      title="Conferma Prezzi"
      @close="show = false"
    >
      {{ pluRepositories }}
    </b-modal>

  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {

  },
  data() {
    return {
      show: false,
      pluRepositories: [],
    }
  },
  methods: {
    openModal() {
      this.show = true
    },
    close() {
      this.show = false
    },
  },
}
</script>

<style>

</style>
