<template>
  <div>
    <app-collapse
      v-for="(ddt, index) in documents"
      :key="index"
      class="m-25"
      type="border"
    >
      <app-collapse-item
        :title="ddtCompanyNameClenedString(ddt.company.Name)"
        :code="ddt.documents.Number.concat(ddt.documents.Numbering !== null ? ddt.documents.Numbering : '')"
      ><!-- :code="ddt.creator.split(' ').pop()" -->
        <b-row>
          <b-col
            cols="12"
            xl="6"
            class=""
          >
            <h5 class="invoice-title">
              Partenza
            </h5>
            <p class="mb-25">
              {{ ddt.company.Address }}
            </p>
            <p class="card-text mb-0">
              {{ ddt.company.Postcode }}
            </p>
            <p class="card-text mb-0">
              {{ ddt.company.City }}, {{ ddt.company.Province }}
            </p>
            <p class="card-text mb-0">
              {{ ddt.company.VatCode }}
            </p>
            <p class="card-text mb-0">
              Tel:{{ ddt.company.Tel }} Fax: {{ ddt.company.Fax }}
            </p>
            <p class="card-text mb-0">
              {{ ddt.company.Email }}
            </p>
          </b-col>
          <b-col
            cols="12"
            xl="6"
            class=""
          >
            <h5 class="invoice-title">
              Arrivo
            </h5>
            <p class="card-text mb-0">
              {{ ddt.documents.CustomerCode }}, {{ ddt.documents.CustomerName }}
            </p>
            <p class="card-text mb-0">
              {{ ddt.documents.CustomerAddress }}
            </p>
            <p class="card-text mb-0">
              {{ ddt.documents.CustomerCity }}, {{ ddt.documents.CustomerProvince }}
            </p>
            <p class="card-text mb-0">
              {{ ddt.documents.CustomerVatCode }}
            </p>
            <p class="card-text mb-0">
              {{ ddt.documents.DeliveryName }}
            </p>
            <p class="card-text mb-0">
              {{ ddt.documents.DeliveryAddress }}
            </p>
            <p class="card-text mb-0">
              {{ ddt.documents.DeliveryCity }}, {{ ddt.documents.DeliveryProvince }}
            </p>
            <p class="card-text mb-0">
              Tipo Documento: {{ ddt.documents.DocumentType }}
            </p>
          </b-col>
        </b-row>
        <hr class="invoice-spacing">
        <h5 class="invoice-title">
          Prodotti
        </h5>
        {{ ddt.documents.TotalWithoutTax }}
        {{ ddt.documents.VatAmount }}
        {{ ddt.documents.Total }}
        <ejs-grid
          :data-source="ddt.documents.Rows.Row"
        >
          <e-columns>
            <e-column
              field="Code"
              header-text="Codice"
              width="120"
              text-align="Right"
              :allow-editing="false"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="Description"
              header-text="Descrizione"
              width="150"
              :allow-editing="false"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="Qty"
              header-text="Qty"
              width="80"
              format="C2"
            />
            <e-column
              field="Um"
              header-text="Um"
              width="80"
              :allow-editing="false"
            />
            <e-column
              field="VatCode"
              header-text="Iva"
              width="80"
              :allow-editing="false"
              :template="ivaTemplate"
            />
            <e-column
              field="Price"
              header-text="Prezzo"
              width="80"
            />
            <e-column
              field="Lot"
              header-text="Lotto"
              width="150"
              :allow-editing="false"
            />
            <e-column
              field="Total"
              header-text="Prezzo"
              width="150"
              :allow-editing="false"
            />
            <e-column
              field="Total"
              header-text="Prezzo"
              width="150"
              :visible="false"
            />
          </e-columns>
        </ejs-grid>
        <!-- <b-row
          v-for="(row, index2) in ddt.documents.Document.Rows.Row"
          :key="index2"
        >
          <b-col
            cols="12"
            class="d-flex justify-content-between"
          >
            <p class="card-text mb-0">
              {{ row.Code }}, {{ row.Description }}
            </p>
            <p class="card-text mb-0">
              {{ row.Qty }} {{ row.Um }}
            </p>
            <p class="card-text mb-0">
              {{ row.Lot }}
            </p>
            <p class="card-text mb-0">
              {{ row.Price }}€/{{ row.Um }}
            </p>
            <p class="card-text mb-0">
              Iva: {{ row.VatCode["#text"] }} %
            </p>
            <p class="card-text mb-0">
              Totale: {{ row.Total }}€
            </p>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end col-xl-8 offset-xl-3"
          >
            <div class="mr-5">
              <p class="card-text mb-0">
                Totale Netto: {{ ddt.documents.TotalWithoutTax }}
              </p>
              <p class="card-text mb-0">
                Iva:{{ ddt.documents.VatAmount }} %
              </p>
              <p class="card-text mb-0">
                Totale Lordo: {{ ddt.documents.Total }}
              </p>
            </div>
          </b-col>
        </b-row>
        <hr class="invoice-spacing">
        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="danger"
              @click="deleteDDT(index)"
            >
              Elimina
            </b-button>
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse></div>
</template>

<script>
import Vue from 'vue'
import { BRow, BCol, BButton } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse_ddt/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse_ddt/AppCollapseItem.vue'
import { GridPlugin, Aggregate } from '@syncfusion/ej2-vue-grids'

Vue.use(GridPlugin)

export default {
  components: {
    BRow, BCol, AppCollapse, AppCollapseItem, BButton,
  },
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      editSettings: {
        allowFiltering: true, allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal',
      },
      dataDocuments: this.documenst,
      ivaTemplate() {
        return {
          template: Vue.component('ivaTemplate', {
            data() { return { data: {} } },
            mounted() {
              // console.log(this.data)
            },
            template: `
              <span>{{ data.VatCode !== null ? data.VatCode['@Perc'] : 'No Iva' }} % </span>
              `,
          }),
        }
      },
      footerSum() {
        return {
          template: Vue.component('footerSum', {
            data() { return { data: {} } },
            // mounted() { console.log(this.data) },
            template: '<span>Totale Netto: {{data.Sum}}€</span>',
          }),
        }
      },
      footerTemplate() {
        return {
          template: Vue.component('footerTemplate', {
            data() { return { data: {} } },
            template: '<span>Iva: {{data.Custom }} %</span>',
          }),
        }
      },
      footerTemplateWithoutVat() {
        return {
          template: Vue.component('footerTemplateWithoutVat', {
            data() { return { data: {} } },
            /* mounted() {
              console.log(this.data)
            }, */
            template: '<span>Iva: {{ data.Custom }}%</span>',
          }),
        }
      },
      footerTemplateVat() {
        return {
          template: Vue.component('footerTemplateWithoutVat', {
            data() { return { data: {} } },
            /* mounted() {
              console.log(this.data)
            }, */
            template: '<span>Totale Lordo: {{ data.Custom }}%</span>',
          }),
        }
      },
    }
  },
  computed: {
    /* shipmentData() {
      return this.dataDocuments.creator.split(' ').pop()
    },
    nameCompany() {
      return this.dataDocuments.company.Name.replace(/&amp;/g, '&')
    }, */
  },
  methods: {
    customAggregateFn(data) {
      // console.log('dataaa', data)
      return data.result.map(item => item.VatCode['#text'])[0]
    },
    customAggregateFnWithoutVat(data) {
      const arrayIva = data.result.map(item => (item.Code !== null ? Number(item.VatCode['@Perc']) : 0))
      return arrayIva.reduce((partialSum, a) => partialSum + a, 0) / arrayIva.length
    },
    customAggregateFnVat(data) {
      const arrayIva = data.result.map(item => (item.Code !== null ? Number(item.VatCode['@Perc']) : 0))
      return arrayIva.reduce((partialSum, a) => partialSum + a, 0) / arrayIva.length
    },
    ddtCompanyNameClenedString(company) {
      // console.log(company)
      return company.replace(/&amp;/g, '&')
      // return company.replace(/&amp;/g, '&')
    },
    deleteDDT(index) {
      // console.log(index)
      this.$emit('delete-ddt', index)
    },
  },
  provide: {
    grid: [Aggregate],
  },
}
</script>

<style lang="scss" scoped>

</style>
